<template>
  <div class="profile-language-wrapper">
    <van-radio-group v-model="choose" icon-size="48px">
      <div class="profile-language-wrapper-item" v-for="locale in langs" :key="locale.lang_code">
        <van-radio :name="locale.lang_code">
          <template #icon="props">
            <div class="ub ub-btw">
              <p class="text">{{ locale.lang }}</p>
              <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon" />
            </div>
          </template>
        </van-radio>
      </div>
    </van-radio-group>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  data () {
    return {
      radio: null,
      activeIcon: require('@/assets/images/profile/radio-active.svg'),
      inactiveIcon: require('@/assets/images/profile/radio-empty.svg')
    }
  },
  computed: {
    choose: {
      get () {
        return this.locale
      },
      set (n) {
        this.onChg(n)
      }
    },
    ...mapGetters(['langs', 'locale'])
  },
  methods: {
    onChg (value) {
      this.updateLocale(value)
      this.$i18n.locale = value
    },
    ...mapMutations([// 采用解构的方式引入
      'updateLocale'
    ])
  }
}
</script>
<style lang="less">
.profile-language-wrapper {
  padding: 28px 30px 0;
  background-color: var(--background-color-4);

  .van-radio {
    width: 100%;
    &__icon {
      width: 100%;
    }
  }

  &-item {
    width: 100%;
    height: 96px;
  }

  .text {
    color: var(--main-text-1);
    font-family: Roboto;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
    /* 150% */
  }

  .img-icon {
    width: 48px;
    height: 48px;
  }
}</style>
